import React, {Suspense, useEffect} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import {useWeb3React} from "@web3-react/core";
import {useDispatch} from 'react-redux'
import GlobalStyle from './style/Global'
import PageLoader from './components/PageLoader'
import useEagerConnect from './hooks/useEagerConnect'
import routes from './routes'
import Layout from './components/Layout'
import ResetStyle from './style/Reset'
import {fetchFarmUserDataAsync} from './state/farms'
import useRefresh from "./hooks/useRefresh";

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'

// eslint-disable-next-line @typescript-eslint/no-unused-vars

// This config is required for number formating
BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
    // Monkey patch warn() because of web3 flood
    // To be removed when web3 1.3.5 is released
    useEffect(() => {
        console.warn = () => null
    }, [])

    useEagerConnect()
    // useFetchPublicData()

    const {account} = useWeb3React()
    const dispatch = useDispatch()
    const {slowRefresh} = useRefresh()

    useEffect(() => {
        if (account) {
            dispatch(fetchFarmUserDataAsync(account))
        }
    }, [account, dispatch, slowRefresh])

    return (
        <Router>
            <ResetStyle/>
            <GlobalStyle/>
            <Suspense fallback={<PageLoader/>}>
                <Layout routes={routes}/>
            </Suspense>
        </Router>
    )
}

export default React.memo(App)
